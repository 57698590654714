import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
}

export const validateSpecialSymbolsExistence = (params: IParams): IValidationError => {
  // eslint-disable-next-line no-useless-escape
  if (/[_!¡?÷¿\/\\+=@#$%ˆ^*()№{}"`|~<>,;:[\]]+/.test(params.value)) {
    return { [params.fieldName]: `Special symbols are not allowed in ${ params.fieldCaption }` };
  }
  return null;
};
