import React, { SyntheticEvent } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import styles from './nextLink.scss';

interface IProps {
  title?: string,
  name?: string,
  className?: string,
  isActive?: boolean,
  isExactActive?: boolean,
  activeClassName?: string,
  to: string | UrlObject,
  target?: string,
  children?: React.ReactNode,
  innerRef?: any,
  onClick?: (e: any) => void,
  onMouseEnter?: (e: any) => void,
  as?: string,
  disabled?: boolean,
}

const NextLink = ({
  name,
  title,
  className,
  to,
  children,
  activeClassName,
  isActive,
  isExactActive,
  as,
  disabled,
  ...other
}: IProps) => {
  const { asPath } = useRouter();
  const toPathname = typeof to === 'string' ? to : to.pathname;
  const isLinkActive = isActive || (isExactActive ? asPath === toPathname : asPath.includes(toPathname));

  const onClick = (e: SyntheticEvent) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <Link
      href={ to }
      as={ as }
      legacyBehavior
    >
      <a
        title={ title }
        data-test={ name }
        className={ cn(className, {
          [activeClassName]: isLinkActive,
          [styles.isDisabled]: disabled,
        }) }
        onClick={ onClick }
        { ...other }
      >
        { children }
      </a>
    </Link>
  );
};

export default NextLink;
