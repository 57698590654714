import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import cn from 'classnames';
import config from '@/Framework/config';
import { FormError, FormField } from '@dealroadshow/uikit';
import styles from './recaptcha.scss';

interface IProps {
  meta: any,
  input: any,
  onChange?: (hash: any) => void,
  className: string,
  visible?: boolean,
  dataTest: string,
  formFieldClassName: string,
}

const defaultProps = {
  onChange: () => {},
  visible: true,
};

class ReCaptcha extends Component<IProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      expired: false,
    };

    this.recaptchaRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!!prevProps.input.value && !this.props.input.value && !this.state.expired) {
      // should be fixed in updates of plugin
      // eslint-disable-next-line no-unused-expressions
      this.recaptchaRef.current?.reset();
    }
  }

  public recaptchaRef;

  onVerified = (hash) => {
    this.setState({
      expired: !hash,
    });

    this.onChanged(hash);
  };

  onExpired = () => {
    this.setState({
      expired: true,
    });

    this.onChanged(null);
  };

  onChanged = (hash) => {
    if (this.props.input && ('onChange' in this.props.input)) {
      this.props.input.onChange(hash);
      return;
    }

    this.props.onChange(hash);
  };

  render() {
    const { meta, visible, className, dataTest, formFieldClassName } = this.props;
    const size = visible ? 'normal' : 'invisible';
    const siteKey = visible
      ? config.recaptcha.visible.siteKey
      : config.recaptcha.invisible.siteKey;
    return (
      <FormField
        isValidationFeedback={ !!(meta && meta.touched && meta.error) }
        dataTest={ dataTest }
        className={ formFieldClassName }
      >
        <div className={ cn(styles.recaptchaWrp, className) }>
          <ReCAPTCHA
            ref={ this.recaptchaRef }
            size={ size }
            sitekey={ siteKey }
            onChange={ this.onVerified }
            onExpired={ this.onExpired }
            onErrored={ () => this.onVerified(null) }
          />
          <FormError
            { ...meta }
            touched={ meta.touched && !this.state.expired }
          />
        </div>
      </FormField>
    );
  }
}
// @ts-ignore
ReCaptcha.defaultProps = defaultProps;

export default ReCaptcha;
