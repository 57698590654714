import { IValidationError } from '../interfaces/ValidationError';

export const emailRegexp = /^(([^<>()[\]\\’.,;:\s@"]+(\.[^<>()[\]\\’.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (params: {
  value: string,
  fieldName: string,
}): IValidationError => {
  if (params.value && !emailRegexp.test(params.value)) {
    return { [params.fieldName]: 'Invalid email entered' };
  }
  return null;
};
