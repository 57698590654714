import { validateCompanyName } from '@/Framework/UI/Organisms/FinalForm/validators/company/validateCompanyName';
import { combineFieldsWithValues, groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateEmail } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';
import { validateName } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateName';
import { validatePhoneNumber } from '@/Framework/UI/Organisms/FinalForm/validators/user/validatePhoneNumber';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';
import { validateRecaptchaVerified } from '@/Framework/UI/Organisms/FinalForm/validators/validateRecaptchaVerified';

const fieldsList = [
  { fieldName: 'corporateEmail', fieldCaption: 'Corporate Email' },
  { fieldName: 'firstName', fieldCaption: 'First Name' },
  { fieldName: 'lastName', fieldCaption: 'Last Name' },
  { fieldName: 'companyName', fieldCaption: 'Company Name' },
];

export default (values, supportRecaptcha) => {
  let fields = combineFieldsWithValues(fieldsList, values);
  let validators = groupValidators(
    validateRequiredFields(
      fieldsList,
      values,
    ),
    validateEmail(fields.corporateEmail),
    validateName(fields.firstName),
    validateName(fields.lastName),
    validateCompanyName(fields.companyName),
    validatePhoneNumber({
      fieldName: 'phone',
      fieldCaption: 'Phone',
      value: values.phone,
      isRequired: true,
    }),
  );

  if (supportRecaptcha) {
    validators = groupValidators(
      validators,
      validateRecaptchaVerified({ fieldName: 'recaptchaToken', value: values.recaptchaToken }),
    );
  }

  return validators;
};
