import { useState } from 'react';
import ScheduleDemoModal from './ScheduleDemoModal';

export default function useScheduleDemoModal() {
  const [isScheduleDemoModalVisible, setIsScheduleDemoModalVisible] = useState(false);

  const showScheduleDemoModal = () => {
    setIsScheduleDemoModalVisible(true);
  };

  const hideScheduleDemoModal = () => {
    setIsScheduleDemoModalVisible(false);
  };

  return {
    ScheduleDemoModal,
    isScheduleDemoModalVisible,
    showScheduleDemoModal,
    hideScheduleDemoModal,
  };
}
