import { ArrowAlign } from '../constants';

interface IOptions {
  arrowAlign: ArrowAlign,
  arrowOffset: number,
  arrowClassName: string,
}

/**
 * state - The state object of modifiers callback function
 * options - Modifiers configuration and options
 */
export default function arrowPosition(state, options: IOptions) {
  const arrowElement = document.getElementsByClassName(options.arrowClassName)[0];
  const { floating, reference } = state.rects;

  let position = 0;

  if (arrowElement) {
    if (options.arrowAlign === ArrowAlign.Right) {
      position = Math.round(floating.width - reference?.x) - options.arrowOffset;
    } else if (options.arrowAlign === ArrowAlign.Left) {
      position = Math.round(options.arrowOffset);
    }
  }

  return position;
}
