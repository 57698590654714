const variantTypes = {
  firm: 'firm',
  secondary: 'secondary',
  action: 'action',
  success: 'success',
  text: 'text',
  outline: 'outline',
  link: 'link',
  warning: 'warning',
};

export default variantTypes;
