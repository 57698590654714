import React from 'react';
import cn from 'classnames';
import variantTypes from './variantTypes';
import buttonStyles from './button.scss';
import { mapVariantToClassName } from './constants';

type VariantType = typeof variantTypes[keyof typeof variantTypes];

interface IProps {
  children?: React.ReactNode,
  type?: 'button' | 'submit' | 'reset',
  variant?: VariantType | null,
  className?: string,
  disabled?: boolean,
  block?: boolean,
  title?: string | React.ReactNode,
  dataTest: string,
  onClick?: (e: any) => void,
  onMouseDown?: (e: any) => void,
}

export const Button = (props: IProps) => {
  const {
    children,
    type = 'button',
    variant = null,
    className,
    disabled = false,
    block = false,
    title,
    dataTest,
    ...restProps
  } = props;

  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={ type }
      className={ cn({
        [mapVariantToClassName[variant]]: variant,
        [buttonStyles.block]: block,
        [buttonStyles.disabled]: disabled,
      }, className) }
      disabled={ disabled }
      data-test={ dataTest }
      { ...restProps }
    >
      { children || (
        <span>{ title }</span>
      ) }
    </button>
  );
};

export default Button;
