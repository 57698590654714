import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tenant from './Tenant';
import { setTenant } from '@/Framework/Tenant/State/Redux/actions';
import { getTenant } from '@/Framework/Tenant/State/Redux/selectors';

function mapStateToProps(state, ownProps) {
  return {
    isTenantSet: getTenant(state) === ownProps.tenant,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTenant,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tenant);
