export enum ArrowAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum PopperOverflowPriority {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}
