import findKey from 'lodash/findKey';
import config from '@/dataroom/application/config/config';
import { IDataroomTenantConfig } from '@/dataroom/domain/vo/DataroomTenantConfig';
import isServer from '@/Framework/Router/Next/isServer';

class DataroomTenantConfig {
  constructor(tenantConfig) {
    Object.assign(this, tenantConfig);
  }

  static fromCode(code: string) {
    const tenantKey = findKey(config.tenant, (tenant: IDataroomTenantConfig) => tenant.code === code);

    if (typeof tenantKey === 'undefined') {
      throw new Error(`Tenant ${ code } not found`);
    }

    return new DataroomTenantConfig(config.tenant[tenantKey]) as IDataroomTenantConfig;
  }

  static fromHostname(hostname: string = isServer() ? undefined : window.location.hostname) {
    if (!this.exists(hostname)) {
      throw new Error(`Tenant not found, hostname: ${ hostname }`);
    }

    return new DataroomTenantConfig(
      config.tenant[findKey(config.tenant, (tenant) => tenant.hostname === hostname)],
    ) as IDataroomTenantConfig;
  }

  static fromObject(object: IDataroomTenantConfig) {
    return new DataroomTenantConfig(object) as IDataroomTenantConfig;
  }

  static exists(hostname: string = isServer() ? undefined : window.location.hostname) {
    return !!config.tenant[findKey(config.tenant, (tenant) => tenant.hostname === hostname)];
  }
}

export default DataroomTenantConfig;
