import React, { useCallback } from 'react';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';

interface IProps {
  input: any,
  onChange: (option: any, input: any) => void,
}

const TimeZoneSelect = (
  {
    input,
    onChange,
    ...otherProps
  }: IProps,
) => {
  const onChangeHandler = useCallback(
    (option) => {
      if (!option) {
        return;
      }
      if (onChange) {
        onChange(option, input);
        return;
      }
      input.onChange(option);
    },
    [input],
  );
  return (
    <Select
      { ...otherProps }
      name={ input.name }
      value={ input.value }
      onChange={ onChangeHandler }
      selectedPlaceholderWhenOpen="Time Zone"
      clearable={ false }
      isSearchable
      isSelectedPlaceholderVisibleWhenOpen
      dataTest="timezoneSelect"
    />
  );
};

export default TimeZoneSelect;
