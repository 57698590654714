import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
}

export const validateRecaptchaVerified = (params: IParams): IValidationError => {
  if (!params.value) {
    return { [params.fieldName]: 'Please verify that you are not a robot' };
  }
  return null;
};
