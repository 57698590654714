import startsWith from 'lodash/startsWith';
import isObject from 'lodash/isObject';
import { IValidationError } from '../interfaces/ValidationError';

/**
 * Checks as far as entered phone number can be considered as "entered".
 */
export const isPhoneEntered = (phone: {
  value: string,
  code: string,
}): boolean => {
  if (!phone || !phone.value || !phone.code) {
    return false;
  }

  const { value, code } = phone;

  return value !== code && value.length >= code.length;
};

/**
 * Should match with selected country dial code.
 * Parameters:
 *  - value - Form field value to validate.
 *  - fieldName - Form field name.
 *  - fieldCaption - Form field caption.
 *  - isRequired - Whether form field value is required.
 *  - isRequirementDependsOnInitialValue - Whether form field value requirement
 *      depends on initial value.
 *      This parameter enables validation like `validateRequiredFieldsWithInitialValues`.
 *      Field which already have stored data applied will be required.
 *  - initialValue - Applied stored data.
 */
interface IParams {
  value: {
    value: string,
    code: string,
  },
  fieldName: string,
  fieldCaption?: string,
  isRequired?: boolean,
  isRequirementDependsOnInitialValue?: boolean,
  initialValue?: string,
}

export const validatePhoneNumber = (params: IParams): IValidationError => {
  if (params && isObject(params.value)) {
    const { value, code } = params.value;

    if (!isPhoneEntered(params.value)) {
      if (params.isRequired) {
        if (params.isRequirementDependsOnInitialValue && !params.initialValue) {
          return null;
        }
        return { [params.fieldName]: `${ params.fieldCaption || 'Field' } is required` };
      }

      return null;
    }

    if (!startsWith(value, code)) {
      return { [params.fieldName]: 'Invalid phone number' };
    }

    if (!(/^[0-9)(\s+-]{10,25}$/i.test(value) &&
          (value.match(new RegExp(/\d/, 'g')) || []).length >= 10
    )) {
      return { [params.fieldName]: 'Invalid phone number' };
    }
  }

  return null;
};
