import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    const timeout = setTimeout(() => window.scrollTo(0, 1), 0);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    !hash && setTimeout(() => window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    }), 0);
  }, [pathname]);

  return <></>;
};

export default ScrollToTop;
