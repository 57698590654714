import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

/**
 * UTC to zoned date
 * @param {string} timeZone - Timezone
 * @param {(number|string|Date)} date - Number value should be in milliseconds!
 * @returns Date
 */
const utcToZonedDate = (
  timeZone: (string | null) = null,
  date: string | number | Date = new Date(),
): Date => utcToZonedTime(date, timeZone);

export default utcToZonedDate;
