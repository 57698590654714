import { ICompany } from '@/users/domain/vo/Company';

export const COMPANY_NAME_MIN_LENGTH = 3;
export const COMPANY_NAME_MAX_LENGTH = 128;

export function getCompanyNameById(companies: ICompany[], id: number): string | null {
  const company = companies.find((company) => company.id === id);
  return company ? company.name : null;
}

export function getCompanyIdByName(companies: ICompany[], name: string): number | null {
  const company = companies.find((company) => company.name === name);
  return company ? company.id : null;
}

export function findCompanyByName(companies: ICompany[], name: string): ICompany {
  return companies.find((company: ICompany) => company.name === name);
}
