import {
  getTime, addMinutes, getMinutes, setMinutes,
  getHours, setHours, getDate, getYear,
  isSameDay, getMonth,
} from 'date-fns';
import { TIME_INTERVAL } from './constants';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

// timeZone - the result object's time zone will be UTC, but the pseudo offset may also be applied.
export const getNowDateRounded = (timeZone: string | null = null) => {
  let nowWithTimeZone = new Date();

  if (timeZone) {
    nowWithTimeZone = utcToZonedDate(timeZone);
  }

  const minutes = getMinutes(getTime(new Date()) - (getTime(new Date()) % (1000 * 60 * TIME_INTERVAL)));
  nowWithTimeZone = setMinutes(nowWithTimeZone, minutes);

  const year = getYear(nowWithTimeZone);
  const month = getMonth(nowWithTimeZone);
  const day = getDate(nowWithTimeZone);
  const hour = getHours(nowWithTimeZone);
  const minute = getMinutes(nowWithTimeZone);
  return addMinutes(new Date(year, month, day, hour, minute, 0, 0), TIME_INTERVAL);
};

export const getScheduleDemoFormTimeValues = (
  { dateAtFormValue, timeZoneFormValue }: { dateAtFormValue: number, timeZoneFormValue: ITimezone },
) => {
  const now = getNowDateRounded(timeZoneFormValue?.timeZone);
  const dateAt = getZonedUTCDate(timeZoneFormValue?.timeZone, getDateFromUnixTime(dateAtFormValue));
  const timeAtFieldMinTimeValue = isSameDay(dateAt, now)
    ? setMinutes(setHours(dateAt, getHours(now)), getMinutes(now))
    : setMinutes(setHours(dateAt, 0), 0);
  return {
    now,
    timeAtFieldMinTimeValue,
  };
};
