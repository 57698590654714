import { useEffect } from 'react';

interface IProps {
  tenant: string,
  setTenant: (tenant: string) => void,
  children?: React.ReactNode,
  isTenantSet: boolean,
}

function Tenant({ setTenant, tenant, children, isTenantSet }: IProps) {
  useEffect(() => { setTenant(tenant); }, [tenant]);
  return (
    <>
      { isTenantSet ? children : null }
    </>
  );
}

export default Tenant;
