// @dodchukdotfinsight TODO: Something
import aud from '@/Framework/assets/flag/currency/aud.inline.svg';
import cad from '@/Framework/assets/flag/currency/cad.inline.svg';
import cny from '@/Framework/assets/flag/currency/cny.inline.svg';
import eur from '@/Framework/assets/flag/currency/eur.inline.svg';
import gbp from '@/Framework/assets/flag/currency/gbp.inline.svg';
import jpy from '@/Framework/assets/flag/currency/jpy.inline.svg';
import usd from '@/Framework/assets/flag/currency/usd.inline.svg';

import { savingsGuaranteeHash } from '@/dataroom/ui/components/Landing/Legacy/Sections/Faq/faqCollection';

export const currencyIcons = {
  AUD: aud,
  CAD: cad,
  CNY: cny,
  EUR: eur,
  GBP: gbp,
  JPY: jpy,
  USD: usd,
};

// Used in finsight ourSolutions page
export const savingsGuaranteeFootnoteUrl = `/pricing#${ savingsGuaranteeHash }`;
