import buttonStyles from './button.scss';
import variantTypes from './variantTypes';

export const mapVariantToClassName = {
  [variantTypes.firm]: buttonStyles.firm,
  [variantTypes.secondary]: buttonStyles.secondary,
  [variantTypes.action]: buttonStyles.action,
  [variantTypes.success]: buttonStyles.success,
  [variantTypes.text]: buttonStyles.text,
  [variantTypes.outline]: buttonStyles.outline,
  [variantTypes.link]: buttonStyles.btnLink,
  [variantTypes.warning]: buttonStyles.warning,
};
