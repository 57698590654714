import { IField } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/Field';
import { IValidationError } from './interfaces/ValidationError';

export const validateRequiredFields = (
  requiredFields: IField[],
  values: { [key: string] : string },
): IValidationError => {
  let errors = {};
  requiredFields.forEach((field) => {
    if (!values[field.fieldName]) {
      errors[field.fieldName] = `${ field.fieldCaption || 'Field' } ${ (field.isPlural && field.fieldCaption) ? 'are' : 'is' } required`;
    }
  });
  return errors;
};
