import React from 'react';
import ReactDatepicker, { DatePickerProps } from 'react-datepicker';
import PortalWrp from '@/Framework/UI/Templates/PortalWrp';
import arrowPositionModifier from './modifiers/arrowPosition';
import { ArrowAlign, PopperOverflowPriority } from './constants';

import { TIME_FORMAT, YEAR_MONTH_DAY_SLASH_FORMAT } from '@/Framework/DateTime/dateFormats';
import styles from './datepicker.scss';

const PortalWrapper = ({ children }) => (
  <PortalWrp>
    <div className={ styles.reactDatepickerPortalEncapsulationGlobalStyles }>{ children }</div>
  </PortalWrp>
);

type IDatePicker = DatePickerProps & {
  dataTest: string,
  datePickerRef?: React.LegacyRef<ReactDatepicker>,
  usePortal?: boolean,
  arrowAlign?: ArrowAlign,
  arrowOffset?: number,
  isPreventOverflowEnabled?: boolean,
  isFlipEnabled?: boolean,
};

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
function DatePicker({
  dateFormat = YEAR_MONTH_DAY_SLASH_FORMAT,
  showTimeSelectOnly,
  datePickerRef,
  dataTest,
  usePortal,
  arrowAlign = ArrowAlign.Right,
  arrowOffset = 18,
  isPreventOverflowEnabled = true,
  isFlipEnabled = true,
  ...otherProps
}: IDatePicker) {
  if (showTimeSelectOnly) {
    dateFormat = TIME_FORMAT;
  }

  return (
    <div className={ styles.reactDatepickerEncapsulationGlobalStyles }>
      <ReactDatepicker
        ref={ datePickerRef }
        dateFormat={ dateFormat }
        popperPlacement="bottom-end"
        className={ styles.reactDatepicker }
        popperContainer={ usePortal ? PortalWrapper : null }
        popperModifiers={ [
          {
            name: 'arrow',
            fn: (data) => {
              data.middlewareData.arrow.x = arrowPositionModifier(data, {
                arrowAlign,
                arrowOffset,
                arrowClassName: 'react-datepicker__triangle',
              });
              return data;
            },
          },
        isPreventOverflowEnabled && {
            name: 'shift',
            options: {
              escapeWithReference: false,
              boundariesElement: window.document.body,
              priority: [
                PopperOverflowPriority.Left,
                PopperOverflowPriority.Right,
              ],
            },
            fn: (state) => state,
          },
          isFlipEnabled && {
            name: 'flip',
            fn: (data) => data,
          },
        ] }
        data-test={ dataTest }
        showTimeSelectOnly={ showTimeSelectOnly }
        { ...otherProps }
      />
    </div>
  );
}

export default DatePicker;
