import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import JsonRpcDispatcher, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

@Dependencies(RpcDispatcher)
class TimeZoneRepository {
  private readonly rpc: JsonRpcDispatcher;

  constructor(rpcDispatcher) {
    this.rpc = rpcDispatcher;
  }

  @withCache
  async getList (): Promise<ITimezone[]> {
    const request = new Request('dm_portal.queries.get_time_zones');
    const response = await this.rpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  }
}

export default TimeZoneRepository;
