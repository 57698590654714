/**
 * Basic action creator
 *
 * @param {String} type
 * @param {?*} payload
 *
 * @return {{payload: *, type: *}}
 */
function createAction(type, payload = undefined) {
  return {
    type,
    ...payload !== undefined && { payload },
  };
}

export default createAction;
