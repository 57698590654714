import config from '@/Framework/config';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

class CallbackUrl {
  /**
   * Will get callbackUrl from provided url. If no url specified in get param will fallback to current href
   *
   * @param {?String} url
   *
   * @return {?String}
   */
  static fromUrl(url = window.location.href) {
    return CallbackUrl.isAllowedUrl(url)
      ? url
      : finsightWebUrl.getUrl();
  }

  /**
   * Will get callback url from callbackUrl get param of url
   *
   * @param url
   */
  static fromGetParam(url = window.location.href) {
    let callbackUrl = new URLSearchParams(url.split('?')[1]).get('callbackUrl');

    if (!callbackUrl) {
      return undefined;
    }

    return CallbackUrl.fromUrl(callbackUrl);
  }

  /**
   * Checks if url is valid callback url (listed in CORS domains)
   *
   * @param {String} url
   *
   * @returns {Boolean}
   */
  static isAllowedUrl(url) {
    if (!url) {
      return false;
    }

    const corsDomains = config.corsDomains.split(',');
    return corsDomains.some(
      (allowedDomain) => url
        .replace(/^(https?:)?\/\//, '')
        .startsWith(
          allowedDomain.replace(/^(https?:)?\/\//, ''),
        ),
    );
  }
}

export default CallbackUrl;
