import isDefined from '@/Framework/dataHelpers/isDefined';

export const appendSearchParams = (url: string, searchParams: { [key: string]: string | number }): string => {
  const extendedUrl = new URL(url);

  Object.keys(searchParams).forEach((key) => {
    const value = searchParams[key];
    isDefined(value) && extendedUrl.searchParams.append(key, value?.toString());
  });

  return extendedUrl.toString();
};

export default appendSearchParams;
