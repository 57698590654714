import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '@/users/domain/User';
import { validateDigitsExistence } from '@/Framework/UI/Organisms/FinalForm/validators/validateDigitsExistence';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateSpecialSymbolsExistence } from '@/Framework/UI/Organisms/FinalForm/validators/validateSpecialSymbolsExistence';
import { IValidationError } from '../interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
  firstName?: string,
}

export const validateName = (params: IParams): IValidationError => {
  const minLengthError = validateMinLength({ ...params, length: NAME_MIN_LENGTH });
  if (minLengthError) {
    return minLengthError;
  }
  const maxLengthError = validateMaxLength({ ...params, length: NAME_MAX_LENGTH });
  if (maxLengthError) {
    return maxLengthError;
  }

  if (validateDigitsExistence(params) || validateSpecialSymbolsExistence(params)) {
    return { [params.fieldName]: `Invalid ${ params.fieldCaption }` };
  }
  return null;
};
