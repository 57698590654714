import config from '@/Framework/config';
import buildUrl from '@/Framework/url/helpers/buildUrl';

export default {
  /**
   * Get base users url
   *
   * @param {Array||String} uris
   *
   * @return {String}
   */
  getUrl(...uris) {
    return buildUrl(config.protocol, `${ config.drsSubdomain }.${ config.globalDomain }`, ...uris);
  },

  /**
   * Get legacy url
   *
   * @param {Array||String} uris
   *
   * @return {String}
   */
  getLegacyUrl(...uris) {
    return buildUrl(config.protocol, config.drsLegacyDomain, ...uris);
  },
};
