import React, { createContext, useContext } from 'react';
import Store from '@/Framework/DI/Providers/Redux/Store';
import Tenant from './TenantContainer';
import { Tenant as TenantType } from '@/Framework/Tenant/vo/types/Tenant';
import { useDIContext } from '@/Framework/DI/DIContext';

export interface ITenantContext {
  tenant: TenantType,
}

export const TenantContext = createContext<ITenantContext>(null);

export const useTenantContext = (): ITenantContext => {
  const context = useContext<ITenantContext>(TenantContext);
  if (!context) {
    throw new Error('useTenantContext must be used within a TenantProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
  useReduxStore?: boolean,
  tenant: TenantType,
}

function TenantContextProvider({ tenant, children, useReduxStore = true }: IProps) {
  const { container } = useDIContext();
  const contextValue = { tenant };
  container.bindContextValue(TenantContext, contextValue);

  let store;
  try {
    store = container.get(Store);
  } catch (e) {
    store = undefined;
  }

  if (!store || !useReduxStore) {
    return (
      <TenantContext.Provider value={ contextValue }>
        { children }
      </TenantContext.Provider>
    );
  }

  return (
    <Tenant tenant={ tenant }>
      <TenantContext.Provider value={ contextValue }>
        { children }
      </TenantContext.Provider>
    </Tenant>
  );
}

export default TenantContextProvider;
