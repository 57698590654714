import React from 'react';

export const virtualDataroomHash = 'virtual-data-room';
export const whyUseHash = 'why-use';
export const lessExpensiveHash = 'less-expensive';
export const dealvdrSetupHash = 'dealvdr-set-up';
export const relatedToFinsightHash = 'related-to-finsight';
export const secureDealvdrHash = 'secure-dealvdr';
export const dealvdrSubcontractHash = 'dealvdr-subcontract';
export const dealvdrsServersHash = 'dealvdrs-servers';
export const gdprCompliantHash = 'gdpr-compliant';
export const engagementLetterHash = 'engagement-letter';
export const billingTermsHash = 'billing-terms';
export const creditCardHash = 'credit-card';
export const savingsGuaranteeHash = 'savings-guarantee';

export const faqCollection = [
  {
    hash: virtualDataroomHash,
    question: 'What is a virtual data room?',
    answer: (
      <>
        <p>
          A virtual data room or "VDR" is a secure online document repository used to share share confidential
          files beyond the corporate firewall. DealVDR’s interface and fanatical 24x7 support are specially tailored
          to accommodate large, complex business workflows across M&A, asset sales, due diligence, warehouse
          facilities, litigation and compliance where the disclosure of sensitive information, appropriate
          permissioning and user access auditing are required.
        </p>
      </>
    ),
  },
  {
    hash: whyUseHash,
    question: 'Why use DealVDR instead of Box or Dropbox?',
    answer: (
      <>
        <p>
          Basic file sharing tools are intended and optimized for simple and personal use. While they may be a
          good fit for one-off access to a folder or file to limited numbers of recipients, their workflows can
          lend themselves to confusion, frustration and error for both administrators and end-users of large,
          complex processes containing dozens of recipients with varied levels of permissioning. Not only do
          they lack fanatical 24x7 support for all stakeholders in a data room, their permissioning, reporting,
          and user auditing capabilities are limited and often restricted to senior level Company administrators
          of the corporate file sharing account. Lastly, most large financial service institutions implement
          strict policies to block access to basic file sharing solutions.
        </p>
      </>
    ),
  },
  {
    hash: lessExpensiveHash,
    question: 'Why is DealVDR so much less expensive than legacy providers',
    answer: (
      <>
        <p>
          Legacy providers typically charge $0.50 to $0.75 per page which can equate to $8,000 to $10,000 per
          gigabyte to host your data. Meanwhile, most people have 64 gigabytes of storage on their smartphone.
          Rest assured, across the thousands of customers that choose DealVDR, there is sufficient margin to
          comfortably provide a superior technology service backed by fanatical 24x7 support at a significantly
          lower price. Our simple, all-inclusive and transparent pricing model also streamlines the engagement
          process, allowing customers to confidently choose DealVDR without the need for RFPs, increasing our
          relative utilization deal by deal. It’s a win-win for both sides.
        </p>
      </>
    ),
  },
  {
    hash: dealvdrSetupHash,
    question: 'How quickly can a DealVDR be set up?',
    answer: (
      <>
        <p>
          A new DealVDR can be setup in minutes. Once you’re ready to get started, simply register your account,
          login, click "Create Data Room" and complete the short 30-second form. Alternatively, you can email
          our fanatical 24x7 support with the name of your data room and a list of users you would like to be
          set as administrators, and we will happily create it on your behalf.
        </p>
      </>
    ),
  },
  {
    hash: relatedToFinsightHash,
    question: 'How is DealVDR related to Finsight?',
    answer: (
      <>
        <p>
          DealVDR is a product of New York City based Finsight Group Inc (referred throughout as Finsight),
          our parent company and the maker of various industry leading financial technology applications.
          To learn more about Finsight, visit{ ' ' }
          <a href="https://www.finsight.com/about" target="_blank">
            www.finsight.com/about
          </a>.
        </p>
      </>
    ),
  },
  {
    hash: secureDealvdrHash,
    question: 'How secure is DealVDR?',
    answer: (
      <>
        <p>
          At the data center level: DealVDR offers a similar level of security as major banks and financial
          institutions. Your information is stored in secure ISO/IEC 27001:2013 certified data centers with
          fire and disaster prevention; keycard access; and equally secure mirror sites that are geographically
          separated in the contenental US. We utilize Amazon AWS as our data center provider with more information
          available
          here:{ ' ' }
          <a href="https://aws.amazon.com/compliance/data-center/controls/" target="_blank">
            https://aws.amazon.com/compliance/data-center/controls/
          </a>
        </p>
        <p>
          At the employee level: Finsight has stringent policies in place to ensure the minimum number of employees
          can access your information - only for client support purposes - from the minimum number of locations.
          We actively monitor and test our security infrastructure and policies using a variety of third parties
          and are SOC II Type II certified annually (available upon request).
        </p>
        <p>
          At the product level: DealVDR offers numerous security and document protection features, including simple
          integration with your Federated Single Sign On (SSO), watermarking, an immutable audit trail and more.
          All documents are encrypted at-rest and in-transit with AES-256 bit encryption.
        </p>
      </>
    ),
  },
  {
    hash: dealvdrSubcontractHash,
    question: 'Does DealVDR subcontract any of its Services?',
    answer: (
      <>
        <p>
          All members of our sales and fanatical 24x7 support team are full-time employees of Finsight.
          Our technology stack does rely on a limited number of service providers such as Amazon AWS to power our
          servers and Google Business Suite for email communication.
        </p>
      </>
    ),
  },
  {
    hash: dealvdrsServersHash,
    question: 'Where are DealVDR’s servers located?',
    answer: (
      <>
        <p>
          All server processing and storage is based in the continental US. Our primary data center is located
          on the US east coast with a geographically separated back-up data center on the US west coast.
        </p>
      </>
    ),
  },
  {
    hash: gdprCompliantHash,
    question: 'Is DealVDR GDPR compliant?',
    answer: (
      <>
        <p>
          Yes. All Finsight services, including DealVDR, are MiFID II and GDPR compliant. We are also EU-U.S.
          Data Privacy Framework Certified.
        </p>
      </>
    ),
  },
  {
    hash: engagementLetterHash,
    question: 'Do I need to execute an engagement letter every time I use DealVDR?',
    answer: (
      <>
        <p>
          No. If you or your client have an engagement letter on file with Finsight, you will notice that
          our full scope of Services are covered subject to specific work orders (now or in the future)
          submitted in writing, by email, over the phone or through the use of the Services. In other words,
          you are free to execute once and continue to utilize our Services as applicable. Please feel free
          to reach out to our fanatical 24x7 support at any time for confirmation.
        </p>
      </>
    ),
  },
  {
    hash: billingTermsHash,
    question: 'What are billing terms?',
    answer: (
      <>
        <p>
          Our default billing term is Net 90 from the date of the provision of the Services. We will of course
          work with you and your client should you require flexibility to align with a transaction’s closing or
          pricing date, up to one year from the date of provisioning.
        </p>
      </>
    ),
  },
  {
    hash: creditCardHash,
    question: 'Can I pay with a credit card?',
    answer: (
      <>
        <p>Yes. Note that invoices processed via credit card are subject to a 3% additional fee.</p>
      </>
    ),
  },
  {
    hash: savingsGuaranteeHash,
    question: '* Am I eligible for the 20% savings guarantee?',
    answer: (
      <>
        <p>
          DealVDR is an enterprise data room solution that provides fanatical 24x7 phone, email and live chat
          support to all data room stakeholders and more advanced functionality than what is available on consumer
          oriented data rooms such as Box, Dropbox, Google Drive and iCloud.
        </p>
        <br />
        <p>
          We are pleased to offer prospective clients a 20% savings guarantee relative to the below comparable
          data room providers:
        </p>
        <ul>
          <li>Ansarada</li>
          <li>DebtDomain</li>
          <li>DebtX</li>
          <li>Firmex</li>
          <li>Intralinks</li>
          <li>Merrill Datasite</li>
          <li>SyndTrak</li>
          <li>Venue</li>
        </ul>
        <br />
        <p>
          To be eligible for our savings guarantee, please be prepared to provide your current contract or
          a written quote provided to you by any of the above providers.
        </p>
      </>
    ),
  },
];
