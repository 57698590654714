import some from 'lodash/some';
import config from '@/dataroom/application/config/config';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { IDataroomTenantConfig } from '@/dataroom/domain/vo/DataroomTenantConfig';

export const isDataroom = (tenant: string) => {
  try {
    const { options } = DataroomTenantConfig.fromCode(tenant);
    return options && options.isDataroom === true;
  } catch (error) {
    return false;
  }
};

export const getDataroomTenantConfigs = () => Object.keys(config.tenant).reduce<IDataroomTenantConfig[]>(
  (acc, key) => {
    let tenantConfig = DataroomTenantConfig.fromObject(config.tenant[key]);
    if (tenantConfig.options.isDataroom) {
      acc.push(tenantConfig);
    }
    return acc;
  },
  [],
);

/**
 * @param tenant {String}
 * @param {String} selectedTransactionTypeId
 * @returns {Boolean}
 */
export const isSectorVisible = (tenant, selectedTransactionTypeId) => {
  const { visibleSectorForTransactionTypeList } = DataroomTenantConfig.fromCode(tenant).options;

  return tenant === config.tenant.tenant17g5.code ||
    some(visibleSectorForTransactionTypeList, (id) => id === selectedTransactionTypeId);
};
