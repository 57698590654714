import React from 'react';
import StickyFooter from '@/Framework/UI/Organisms/StickyFooter';
import { footerConfig } from './config';

interface IProps {
  tenant: string,
}

const Footer = (props: IProps) => (
  <StickyFooter
    config={ footerConfig(props.tenant) }
  />
);

export default Footer;
