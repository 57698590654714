import { Dependencies } from 'constitute';
import JsonRpcDispatcher, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { IScheduleDemoPayload } from '@/Framework/UI/Organisms/ScheduleDemo/vo/ScheduleDemoPayload';

@Dependencies(RpcDispatcher)
class ScheduleDemoRepository {
  private readonly rpc: JsonRpcDispatcher;

  constructor(rpcDispatcher) {
    this.rpc = rpcDispatcher;
  }

  requestScheduledDemoPublic = async (payload: IScheduleDemoPayload) => {
    const request = new Request('dm_portal.request_scheduled_demo_public', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  requestScheduledDemoPrivate = async (payload: IScheduleDemoPayload) => {
    const request = new Request('dm_portal.request_scheduled_demo', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default ScheduleDemoRepository;
