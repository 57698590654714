import { format } from 'date-fns-tz';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';

export function getTimeZoneDisplayNameDate(timeZone) {
  let offset = format(getZonedUTCDate(timeZone.timeZone), 'XXX', { timeZone: timeZone.timeZone });
  return `(GMT${ offset }) ${ timeZone.name } (${ timeZone.abbr })`;
}

/**
 *
 * @param {Array} timeZones
 * @param {String} id
 * @return {Object|null}
 */
export function getTimeZoneById(timeZones, id) {
  return timeZones.find((timeZone) => timeZone.id === id);
}

/**
 *
 * @param {Array} timeZones
 * @return {Object|null}
 */
export function getDefaultTimeZone(timeZones) {
  return timeZones.filter((timeZone) => !!timeZone.default)[0];
}

/**
 * @param {Array} timeZoneList
 * @return {*}
 */
export const guessTimeZone = (timeZoneList) => (
  timeZoneList.find((timeZone) => timeZone.timeZone === Intl.DateTimeFormat().resolvedOptions().timeZone) ||
  timeZoneList.find((tz) => tz.default)
);
